import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import icon1 from "../img/Icon/1.png";
import icon2 from "../img/Icon/2.png";
import icon3 from "../img/Icon/3.png";

i18n.use(initReactI18next).init({
    
    debug: true,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en:{
            translation: {

                header1: 'About Us',
                content1: "Trusted Flower Shop & Floral Services",
                content2: "Custom Flowers, Beautifully Designed",
                content3 : "At Linda Flower,",
                content4: 'where every arrangement is crafted with passion and precision. Our talented florists are dedicated to creating custom flower designs that beautifully capture your sentiments, whether for weddings, birthdays, anniversaries, or just because. Each bloom is carefully handpicked for quality and freshness, ensuring that every bouquet reflects our commitment to excellence and artistry.',
                content5: 'We pride ourselves on providing exceptional service, attention to detail, and a personalized floral experience for every customer. From intimate gatherings to grand celebrations, Linda Flower is here to make your floral visions come to life. Discover why we’re a trusted choice for stunning, memorable flowers that brighten any occasion.',
                content6: 'Discover Elegance & Freshness',
                content7: 'Expert Floral Creations',
                content8 : 'Services',
                content9: 'Experience expert floral designs and personalized service at Linda Flowers, your trusted destination for stunning bouquets and arrangements. Offering a peaceful and elegant atmosphere, we ensure every creation brings beauty, freshness, and joy to your special moments.',
                content10: 'Conveniently serving the Sacramento, Stockton, and Elk Grove areas, Linda Flowers offers a luxurious floral experience with stunning arrangements and personalized designs for every occasion. Our skilled florists are dedicated to crafting beautiful, high-quality bouquets that bring joy and elegance to your events. We proudly provide free delivery for funeral flowers and also offer delivery services for all your floral needs, ensuring your flowers arrive fresh and on time. Serving Sacramento, Stockton, Elk Grove, and surrounding communities with exceptional service and creativity.',
                button: 'Order Now',

                icon: [
                    {
                        id: 1,
                        name: 'Birthday Flowers',
                        desc: "Celebrate life's special moments with vibrant, custom-made birthday bouquets that perfectly capture the joy of the occasion.",
                        img: icon1
                    },
                    {
                        id: 2,
                        name: 'Custom Floral Arrangements',
                        desc: "Our tailored floral designs bring your unique vision to life, ensuring every arrangement is as personal and special as your occasion.",
                        img: icon2
                    },
                    {
                        id: 3,
                        name: 'Holiday Flowers',
                        desc: "Brighten up your holidays with festive floral arrangements crafted to add warmth, beauty, and seasonal charm to any celebration.",
                        img: icon3
                    }
                ],

                Section2: [
                    {
                        id: 1,
                        name: 'Birthday',
                    },
                    {
                        id: 2,
                        name: 'Funeral',
                    },
                    {
                        id: 3,
                        name: 'Events',
                    },
                    {
                        id: 4,
                        name: 'Package'
                    }
                ],

                Section3: [
                    {
                        id: 1,
                        name: 'Expertise in Floral Design',
                        desc: 'At Linda Flowers, our skilled florists create stunning, high-quality arrangements tailored to every occasion, showcasing creativity and attention to detail.'
                    },
                    {
                        id: 2,
                        name: 'Luxurious Floral Selections',
                        desc: 'Discover an exquisite selection of premium flowers, carefully curated to bring elegance and charm to any event or space.'
                    },
                    {
                        id: 3,
                        name: 'Personalized Service',
                        desc: 'We offer custom floral designs tailored to your unique preferences, ensuring every bouquet reflects your vision and leaves a lasting impression.'
                    }
                ]
                
            }
        },
        vn:{
            translation: {
                header1: 'Về Chúng Tôi',
                content1: "Cửa Hàng Hoa Đáng Tin Cậy & Dịch Vụ Hoa Tươi",
                content2: "Hoa Tùy Chỉnh, Thiết Kế Đẹp Mắt",
                content3: 'Chào mừng bạn đến với Linda Flower,',
                content4: 'nơi mỗi bó hoa đều được tạo nên bằng sự đam mê và tinh tế. Những nghệ nhân cắm hoa tài năng của chúng tôi luôn tận tâm tạo ra các thiết kế hoa tùy chỉnh, hoàn hảo để thể hiện cảm xúc của bạn, dù là trong dịp cưới, sinh nhật, kỷ niệm, hay chỉ đơn giản là để mang lại niềm vui. Mỗi bông hoa đều được lựa chọn kỹ lưỡng về chất lượng và độ tươi, đảm bảo rằng mỗi bó hoa đều thể hiện sự cam kết của chúng tôi về sự hoàn hảo và nghệ thuật.',
                content5: 'Chúng tôi tự hào mang đến dịch vụ tuyệt vời, sự chú ý tỉ mỉ đến từng chi tiết, và một trải nghiệm hoa tùy chỉnh dành riêng cho mỗi khách hàng. Từ những buổi gặp gỡ thân mật đến các buổi lễ kỷ niệm hoành tráng, Linda Flower luôn sẵn sàng biến tầm nhìn hoa của bạn thành hiện thực. Khám phá lý do vì sao chúng tôi là lựa chọn đáng tin cậy cho những bó hoa tuyệt đẹp và đầy ý nghĩa cho mọi dịp.',
                content6: 'Khám Phá Sự Thanh Lịch & Tươi Mát',
                content7: 'Thiết Kế Hoa Chuyên Nghiệp & Dịch Vụ',
                content8 : 'Tùy Chỉnh',
                content9: 'Trải nghiệm những thiết kế hoa tinh tế và dịch vụ tùy chỉnh tại Linda Flowers, địa chỉ đáng tin cậy của bạn cho những bó hoa và kiểu dáng độc đáo. Với không gian thanh lịch và yên bình, chúng tôi cam kết mỗi tác phẩm đều mang lại vẻ đẹp, sự tươi mới và niềm vui cho những khoảnh khắc đặc biệt của bạn.',
                button: 'Dặt Hàng Ngay',
                content10: 'Dễ dàng phục vụ khu vực Sacramento, Stockton, và Elk Grove, Linda Flowers mang đến trải nghiệm hoa cao cấp với các thiết kế độc đáo và đẹp mắt cho mọi dịp. Đội ngũ chuyên gia của chúng tôi tận tâm tạo ra những bó hoa chất lượng cao, mang lại niềm vui và sự thanh lịch cho sự kiện của bạn. Chúng tôi tự hào cung cấp dịch vụ giao hoa miễn phí cho hoa tang lễ và giao hoa cho tất cả nhu cầu của bạn, đảm bảo hoa luôn tươi mới và đến đúng thời gian. Phục vụ Sacramento, Stockton, Elk Grove, và các cộng đồng lân cận với dịch vụ tận tâm và sáng tạo.',

                icon: [
                    {
                        id: 1,
                        name: 'Hoa Sinh Nhật',
                        desc: "Tạo dấu ấn cho ngày đặc biệt với bó hoa sinh nhật rực rỡ, được thiết kế riêng để thể hiện niềm vui và tình cảm.",
                        img: icon1
                    },
                    {
                        id: 2,
                        name: 'Hoa Tùy Chỉnh',
                        desc: "Chúng tôi mang đến các thiết kế hoa tùy chỉnh, phù hợp với ý tưởng và phong cách của bạn, làm cho mỗi dịp trở nên đáng nhớ.",
                        img: icon2
                    },
                    {
                        id: 3,
                        name: 'Hoa Lễ Hội',
                        desc: "Tô điểm không gian lễ hội của bạn với những bó hoa trang trí đặc sắc, mang đến không khí ấm cúng và đầy màu sắc.",
                        img: icon3
                    }
                ],

                Section2: [
                    {
                        id: 1,
                        name: 'Sinh Nhật',
                    },
                    {
                        id: 2,
                        name: 'Tang Lễ',
                    },
                    {
                        id: 3,
                        name: 'Thiết Kế',
                    },
                    {
                        id: 4,
                        name: 'Trọn Gói'
                    }
                ],

                Section3: [
                    {
                        id: 1,
                        name: 'Chuyên Gia Thiết Kế Hoa',
                        desc: 'Tại Linda Flowers, đội ngũ chuyên gia của chúng tôi tạo ra những bó hoa độc đáo và chất lượng cao, phù hợp cho mọi dịp, với sự sáng tạo và chú ý đến từng chi tiết.'
                    },
                    {
                        id: 2,
                        name: 'Lựa Chọn Hoa Sang Trọng',
                        desc: 'Khám phá bộ sưu tập hoa cao cấp, được chọn lọc kỹ lưỡng để mang lại vẻ đẹp và sự thanh lịch cho bất kỳ sự kiện hoặc không gian nào.'
                    },
                    {
                        id: 3,
                        name: 'Dịch Vụ Cá Nhân Hóa',
                        desc: 'Chúng tôi cung cấp thiết kế hoa tùy chỉnh theo sở thích của bạn, đảm bảo mỗi bó hoa đều phản ánh ý tưởng của bạn và để lại ấn tượng sâu sắc.'
                    }
                ]
                
            }
        }
    }
});

export default i18n;
import React from 'react';
import ReactPaginate from 'react-paginate';
import { Gallery as Glery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';


export default function Portfolio({Data , currentPageIndex , setPageCount}){

    const limit = 6;
    
    const options = {
        zoom: false,
        tapAction: 'close',
        doubleTapAction: false,
    };
    const endOffset = currentPageIndex + limit;

    const currentItems = Data.slice(currentPageIndex, endOffset);
    
    const pageC = Math.ceil(Data.length / limit);

    const handlePageClick = (e) => {
        const newOffset = e.selected * limit;
        setPageCount(newOffset);
    };

    return(

        <>
            <div className="grid lg:grid-cols-3 gap-5 md:grid-cols-2 w-full justify-items-center p-4 cursor-pointer">
                <Glery options={options}>
                    {currentItems.map((fname, index) => (
                        <div key={index} className="shadow-lg w-full lg:h-full h-[400px] overflow-hidden rounded-lg">
                            <Item width="900" height="900" original={fname} alt="#">
                                {({ ref, open }) => (
                                    <div className="sticky w-full h-[400px]">
                                        <img ref={ref} onClick={open} className="rounded-lg w-full h-[400px] lg:h-full ease-in-out transition-all duration-1000 lg:hover:scale-125" alt="#" src={fname} />
                                        {/* <div ref={ref} onClick={open} className="hidden absolute p-8 inset-0 z-10 bg-black lg:flex flex-col justify-center opacity-0 hover:opacity-100 ease-linear duration-500 transition-all bg-opacity-50">
                                            <div className=" bg-black/20 p-1 lg:p-4">
                                                <div className=" flex justify-center text-lg text-white space-x-1">
                                                    <span className="">View Image</span>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                )}
                            </Item>
                        </div>
                    ))}
                </Glery>
            </div>

            <ReactPaginate
                previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                breakLabel={<span className="mr-4 text-black">...</span>}
                pageCount={pageC}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName="pagination flex items-center justify-center mt-8 mb-4"
                pageClassName="block border-1 border-solid border-lightGray hover:bg-lightGray w-10 h-10 flex items-center justify-center rounded-md"
                pageLinkClassName="page-link"
                previousClassName="page-item text-center p-2 border-2 group shadow-lg hover:bg-main-color border-main-color w-12 rounded-lg"
                previousLinkClassName="page-link group-hover:text-white text-main-color"
                nextClassName="page-item text-center p-2 border-2 shadow-lg group hover:bg-main-color border-main-color w-12 rounded-lg"
                nextLinkClassName="page-link group-hover:text-white text-main-color"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="text-main-color font-bold"
                disabledClassName="text-gray-300 font-bold"
            />
        </>
    );
};
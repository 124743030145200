import {React , useState , useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from 'react-i18next';


function FloatBtn({setIsclick , isClick}){
    const Data = [faFacebookF , faInstagram]
    const [isThere , SetisThere] = useState(false);
    const [isVn , setVn] = useState(false);

    const {i18n} = useTranslation();

    const VN_Btn = (lang) => {
        setVn(!isVn);
        setIsclick(!isClick);
        i18n.changeLanguage(lang);
    };
    
    const changeisThere = () =>{
        if ( (window.pageYOffset > 50) || (window.pageYOffset > window.screen.height - 5) ){
            SetisThere(true);
        }
        else{
            SetisThere(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll' , changeisThere)
        return () => {
            window.removeEventListener('scroll' , changeisThere)
        }
    } , [])

    return (
        <main>
            <div className= {`${isThere?"opacity-100": "opacity-0"} flex flex-col lg:space-y-2 fixed bottom-4 lg:bottom-12 z-10 right-3 lg:right-8`}>
                {Data.map((s,index) => (
                    <div key={index} className= "rounded-full flex cursor-pointer justify-center items-center p-2">
                        
                        {
                            index === 0 && (
                                <button onClick={() => window.location.href = 'https://www.facebook.com/profile.php?id=100013901107271'} className="" type="button">
                                    <FontAwesomeIcon icon={s} className="w-6 h-6 lg:w-8 lg:h-8 text-sky-400" />
                                </button>
                            )
                        }
                        {
                            index === 1 && (
                                <button onClick={() => window.location.href = 'https://www.instagram.com/lindaflowerseg/'} className="" type="button">
                                    <FontAwesomeIcon icon={s} className="w-6 h-6 lg:w-8 lg:h-8 text-rose-400" />
                                </button>
                            )
                        }
                    </div>
                ))}
                {isVn?
                    (
                        <button className="rounded-full lg:px-4 px-2 py-2 shadow-lg bg-btn-color text-main-color font-bold cursor-pointer" type="button" onClick={() => VN_Btn('en')}>
                            <span>EN</span>
                        </button>
                    ): (
                        <button className="rounded-full lg:px-4 px-2 py-2 shadow-lg bg-btn-color text-main-color font-bold cursor-pointer" type="button" onClick={() => VN_Btn('vn')}>
                            <span>VN</span>
                        </button>
                    )
                }
            </div>
        </main>
    );
};

export default FloatBtn;
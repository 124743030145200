import './App.css';
import React , {useState , useEffect} from 'react';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';


import Navbar from './Components/Navbar';
import FloatBtn from './Components/Floatbtn';

import Home from './Views/Home';


function App() {

	const {t} = useTranslation();

	const [activeTab, setActiveTab] = useState("Home");

	const [isPortfolio, setActivePortfolio] = useState("Birthday");

	const [isClick , setIsclick] = useState(0);

	const checkTree = useLocation().pathname.includes('/Review');


	function scrollSec(el){
        window.location.href = `/${el}`;
    };

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
		scrollSec(tabName);
	};

	const isActive = (tabName) => {
		return activeTab === tabName ? " text-main-color border-t-main-color border-t-[1px] p-2" : "";
	};

	const location = useLocation();
	
	useEffect(() => {

		const currentPath = location.pathname;

		switch (currentPath) {
			
			case "/Gallery":
				setActiveTab("Gallery");
				break;

			default:
				setActiveTab("Home");
				break;

			}
			
	}, [location]);

	return (
		!checkTree?(
			<main className=' font-Monterast overflow-x-hidden'>
				<Navbar isClick = {isClick} activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />
				<Fade>
					<Routes>
					<Route path = "/" element = {<Home isClick={isClick} setIsclick={setIsclick} setActivePortfolio={setActivePortfolio} isPortfolio={isPortfolio} multiLang = {t} activeTab = {activeTab} setActiveTab = {setActiveTab} handleTabClick = {handleTabClick} isActive = {isActive} />} />
					<Route path="*" element={<Navigate to="/" />} />
					</Routes>
				</Fade>
				{/* <Footer /> */}
				<FloatBtn isClick={isClick} setIsclick={setIsclick} />
			</main>
		):(
			<Routes>
				{/* <Route path = '/Reviews' element = {<SocialTree />} /> */}
			</Routes>
		)
	);
}

export default App;
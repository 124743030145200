import React, { useState } from "react";
import { Slide, Fade, JackInTheBox } from "react-awesome-reveal";
import CarouselHome from "../Components/Carousel";

import hpic from "../img/hpic.png";
import bg1 from "../img/bg6.png";
import bg2 from "../img/tesst.png";

import Portfolio from "../Components/Porfolio";


const birthdayImages = require.context("../img/Flowers/BD", false, /\.(png|jpg|jpeg)$/).keys().map((file) => require(`../img/Flowers/BD/${file.replace('./', '')}`));
const funeralImages = require.context("../img/Flowers/Funeral", false, /\.(png|jpg|jpeg)$/).keys().map((file) => require(`../img/Flowers/Funeral/${file.replace('./', '')}`));
const eventsImages = require.context("../img/Flowers/Events", false, /\.(png|jpg|jpeg)$/).keys().map((file) => require(`../img/Flowers/Events/${file.replace('./', '')}`));
const packageImages = require.context("../img/Flowers/Package", false, /\.(png|jpg|jpeg)$/).keys().map((file) => require(`../img/Flowers/Package/${file.replace('./', '')}`));

const imageMapping = {
    'Birthday': birthdayImages,
    'Funeral': funeralImages,
    'Events': eventsImages,
    'Package': packageImages,
	'Sinh Nhật': birthdayImages,
    'Tang Lễ': funeralImages,
    'Thiết Kế': eventsImages,
    'Trọn Gói' : packageImages
};

export default function Home({ multiLang , isPortfolio , setActivePortfolio , isClick , setIsclick}) {

    const IconData = multiLang('icon', { returnObjects: true });

	const Section2 = multiLang('Section2', { returnObjects: true });

	const Section3 = multiLang('Section3', { returnObjects: true });

	
	const [currentPageIndex, setPageCount] = useState(0);


    const handlePortfolio = (portfolio) => {
        setActivePortfolio(portfolio);
		setPageCount(0);
    };
	

    const isActivePortfolio = (por) => {
        return isPortfolio === por ? " text-dark-txt" : "text-txt-color/50";
    };

    const fnames = imageMapping[isPortfolio] || [];

    return (
        <main className="">
            <section>
                <CarouselHome setIsclick={setIsclick} isClick={isClick} multiLang={multiLang} />
            </section>

            <section className=" bg-bg-color">
                <div className=" p-5 pt-12 pb-8 lg:pt-28">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-12  lg:gap-24 place-items-center lg:grid-cols-3">
                            <Fade delay={5}>
                                {IconData.map((s) => (
                                    <div key={s.id} className=" hover:opacity-50 cursor-pointer flex text-center justify-center items-center space-y-5 flex-col">
                                        <img alt={s.name} className="w-28" src={s.img} />
                                        <div className=" flex flex-col space-y-5">
                                            <div className=" space-y-2">
                                                <div>
                                                    <h2 className=" text-2xl font-Playfair text-main-color">{s.name}</h2>
                                                </div>
                                                <div>
                                                    <span className="">
                                                        {s.desc}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-bg-color">
                <div className=" p-3 lg:p-5 pt-12 pb-10 lg:pb-24 lg:pt-28">
                    <div className="mx-auto max-w-screen-xl">
                        <div className=" grid gap-4 lg:gap-6 place-items-center lg:grid-cols-2">
                            <JackInTheBox>
                                <div className=" cursor-pointer lg:h-[550px] border-main h-[320px] lg:p-4 p-2 md:block overflow-hidden">
                                    <img alt="test" className="ease-in-out border-main h-full transition-all duration-1000 hover:scale-125" src={hpic} />
                                </div>
                            </JackInTheBox>
                            <div className="space-y-4 lg:px-8 lg:space-y-8 lg:py-4 justify-center text-center">
                                <div className=" text-[30px] flex flex-col items-center lg:text-4xl tracking-wide space-y-2">
                                    <div className=" border-[1px] border-main-color w-16"></div>
                                    <h2 className=" text-main-color font-Playfair">
                                        {multiLang('header1')}
                                    </h2>
                                </div>
                                <div className=" flex flex-col md:text-lg space-y-6 lg:space-y-8">
                                    <div className="space-y-4 flex flex-col">
                                        <div className=" space-x-1">
                                            <span className=" font-bold text-xl">
                                                {multiLang('content3')}
                                            </span>
                                            <span className="">
                                                {multiLang('content4')}
                                            </span>
                                        </div>
                                        <span className=" lg:block hidden">
                                            {multiLang('content5')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-[#EDF4EA]">
				<Fade delay={100}>
					<div className=" lg:p-5 p-2 pt-12 pb-12 lg:pt-12 lg:pb-24">
						<div className=" mx-auto max-w-screen-xl">
							<div className="flex items-center space-y-5 lg:space-y-10 flex-col">
								<div className="tracking-wider space-y-2">
									<div className=" border-[1px] border-main-color w-20"></div>
									<h2 className=" text-main-color font-Playfair text-[30px] text-center lg:text-[40px]">
										Our Portfolio
									</h2>
								</div>
								<div className=" flex w-full justify-evenly">
									{Section2.map((data) => (
										<div onClick={() => handlePortfolio(data.name)} className=" cursor-pointer flex flex-col items-center justify-center space-y-2" key={data.id}>
											<div className={isPortfolio === data.name ? "border-[1px] bg-main-color border-main-color w-8 lg:w-16" : "hidden"}></div>
											<div>
												<h2 className={`${isActivePortfolio(data.name)} text-lg lg:text-2xl`}>{data.name}</h2>
											</div>
										</div>
									))}
								</div>
								<div className=" h-full">
                                    <Portfolio setPageCount = {setPageCount} currentPageIndex = {currentPageIndex} Data = {fnames} />
                                </div>
							</div>
						</div>
					</div>
				</Fade>
            </section>

			<section className="bg-bg5 bg-cover bg-center lg:bg-fixed">
                <div className=" p-5 pt-12 lg:pb-24 lg:pt-24">
                    <div className="mx-auto max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <Fade delay={50}>
                                <div className="flex flex-col space-y-5 lg:space-y-8">
                                    <span className="text-xl lg:text-3xl font-Playfair">{multiLang('content6')}</span>
                                    <div className="flex flex-col space-y-1 lg:space-y-2">
                                        <span className="text-3xl lg:text-4xl font-Osward text-txt-color">
											{multiLang('content7')}
                                        </span>
                                        <span className="text-4xl font-Playfair italic text-dark-txt">
											{multiLang('content8')}
                                        </span>
                                    </div>
                                    <span className="lg:text-lg">
										{multiLang('content9')}
                                    </span>
                                    <div className=" ">
                                        <ul className=" space-y-8">
											{Section3.map((s) => (
												<li key = {s.id} className=" flex flex-col">
													<div className=" flex space-x-2 text-xl lg:text-2xl">
														<span  className=" italic">0{s.id}</span>
														<span className=" font-bold italic text-main-color">
															{s.name}
														</span>
													</div>
													<span className=" text-black/70 lg:ml-8 ml-1">
														{s.desc}
													</span>
												</li>
											))}
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                            <div className=" lg:hidden border-main2 h-[250px] rounded-xl flex">
                                <img className="border-main2 h-full" alt="da" src={bg1} />
                            </div>
                            <div className="hidden border-main2 hover:opacity-50 cursor-pointer lg:block lg:bg-bg6 lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

			<section id="Contact" className=" bg-bg-color">
                <div className="lg:pt-12 pt-6 pb-4 space-y-4">
                    <div className="lg:p-4 h-full p-2">
                        <div className=" grid h-full lg:grid-cols-2 gap-8 lg:gap-12">
                            <div className=" lg:h-full">
								<img className=" lg:h-full" alt="da" src={bg2} />
                            </div>
                            <Slide delay={4} direction="down">
                                <div className=" lg:py-24 mx-2 lg:mx-0 flex flex-col space-y-4">
                                    <div className=" text-[30px] lg:text-[44px] flex flex-col tracking-wide space-y-[1px]">
                                        <div className=" border-[1px] border-main-color w-16"></div>
                                        <h2 className=" font-Osward text-main-color">
                                            Contact Us
                                        </h2>
                                    </div>
                                    <div className=" max-w-2xl flex flex-col space-y-5 lg:space-y-6">
                                        <div>
                                            <span>
                                                {multiLang('content10')}
                                            </span>
                                        </div>
										<div>
                                            <ul className="text-xl space-y-1">
                                                <li className=" text-main-color text-xl font-Playfair lg:text-2xl">Linda Flowers</li>
                                                <li>(408) 338-5276 --- Vietnamese</li>
                                                <li>(669) 247-9127 --- English</li>
                                            </ul>
                                        </div>
                                        <div className="">
                                            {!isClick? 
                                                (
                                                    <button onClick={() => {
                                                        window.location.href = "tel:6692479127";
                                                        }} className=" mt-4 hover:shadow-lg flex items-center justify-center space-x-2 bg-main-color text-white border-2 border-main-color hover:bg-main-color ease-in-out delay-100 duration-150 md:text-lg w-[200px] py-3 px-4 group cursor-pointer md:tracking-wide">
                                                        <span>{multiLang('button')}</span>
                                                    </button>
                                                ) : (
                                                    <button onClick={() => {
                                                        window.location.href = "tel:4083385276";
                                                        }} className=" mt-4 hover:shadow-lg flex items-center justify-center space-x-2 bg-main-color text-white border-2 border-main-color hover:bg-main-color ease-in-out delay-100 duration-150 md:text-lg w-[200px] py-3 px-4 group cursor-pointer md:tracking-wide">
                                                        <span>{multiLang('button')}</span>
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    );
};

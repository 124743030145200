import { React, useState } from "react";
import Logo1 from "../img/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons"


function Navbar(props) {
    // const [Click, setClick] = useState(false);
    const [Scroll, SetScroll] = useState(false);

    const Change_color = () => {
        if (window.scrollY >= 10) {
            SetScroll(true);
        }
        else { SetScroll(false); }
    };
    window.addEventListener('scroll', Change_color);

    return (
        <div className="">
            <nav className={Scroll ? " px-4 transition ease-out duration-200 text-black  delay-100 flex justify-between lg:justify-around w-full fixed top-0 left-0 right-0 z-10 " :
                "flex text-black lg:justify-around rounded-full bg-none lg:w-screen fixed px-4 lg:px-8 top-0 left-0 right-0 z-10"}>
                <div className= {`${Scroll? "bg-white text-black" : "hidden"} lg:mt-6 mt-3 rounded-full flex w-screen lg:w-3/4 lg:py-[2px] justify-around`}>
                    <div className="flex items-center cursor-pointer group">
                        <img src={Logo1} alt="" className= {Scroll? " w-14 md:w-20 group-hover:opacity-70": "w-14 md:w-24 group-hover:opacity-70"} />
                    </div>
                    <div className={Scroll ? "lg:flex text-md hidden items-center space-x-20 text-xl capitalize" :
                        "lg:flex text-md hidden items-center space-x-20 text-xl capitalize"}>
                        <a onClick={() => props.handleTabClick("Home")} className={`${props.isActive("Home")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/">
                            Home
                        </a>
                    </div>
            
                    <div className={Scroll ? "lg:hidden text-3xl cursor-pointer flex items-center space-x-4" :
                        "lg:hidden space-x-4 text-3xl cursor-pointer flex items-center"}>
                        {!props.isClick ? 
                            (
                                <div onClick={() => window.location.href = "tel:6692479127"} className={`${Scroll? "bg-green-400" : "border-0" } flex items-center justify-center w-[25px] h-[25px] rounded-full`}>
                                    <FontAwesomeIcon className= {`${Scroll? "text-white" : "text-green-400"} flex text-sm`} icon = {faPhone}/>
                                </div>
                            ):(
                                <div onClick={() => window.location.href = "tel:4083385276"} className={`${Scroll? "bg-green-400" : "border-0" } p-[2px] rounded-full`}>
                                    <FontAwesomeIcon className= {`${Scroll? "text-white" : "text-green-400"} flex text-sm`} icon = {faPhone}/>
                                </div>
                            )
                        }
                    </div>
                </div>
            </nav>
        </div>
    );
};


export default Navbar;